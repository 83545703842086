/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "enrollment/initialization-bff/api/v1/initialization_service.proto" (package "enrollment.initialization_bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { Empty } from "../../../../google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message enrollment.initialization_bff.api.v1.QueryParam
 */
export interface QueryParam {
    /**
     * @generated from protobuf field: string key = 1;
     */
    key: string;
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string;
}
/**
 * @generated from protobuf message enrollment.initialization_bff.api.v1.InitializeParticipantInfoRequest
 */
export interface InitializeParticipantInfoRequest {
    /**
     * @generated from protobuf oneof: associated_resource
     */
    associatedResource: {
        oneofKind: "planDefinitionId";
        /**
         * This is the FHIR ID of the PlanDefinition resource representing one of the following:
         * 1. The PreToDoFeed config associated with the ResearchStudy the participant is going to
         * enroll in.
         * 2. The New User Experience (NUX) Base CareProgram the participant is going to enroll in.
         *
         * @generated from protobuf field: string plan_definition_id = 4;
         */
        planDefinitionId: string;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: repeated enrollment.initialization_bff.api.v1.QueryParam utm_params = 2;
     */
    utmParams: QueryParam[];
    /**
     * @generated from protobuf field: string google_analytics_client_id = 3;
     */
    googleAnalyticsClientId: string;
    /**
     * @generated from protobuf field: string language = 5;
     */
    language: string;
}
/**
 * The response might also have a JWT (the PSAT) in the "ciam-control-values" metadata
 *
 * @generated from protobuf message enrollment.initialization_bff.api.v1.InitializeParticipantInfoResponse
 */
export interface InitializeParticipantInfoResponse {
    /**
     * The URL to redirect the user to after initialization is complete.
     * See the flow chart in http://goto.google.com/vcr-recruit-reconcile-identity for more details.
     *
     * @generated from protobuf field: string redirect_url = 1;
     */
    redirectUrl: string;
}
/**
 * @generated from protobuf message enrollment.initialization_bff.api.v1.UpdateMockCiamInfoRequest
 */
export interface UpdateMockCiamInfoRequest {
    /**
     * @generated from protobuf field: string person_id = 1;
     */
    personId: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class QueryParam$Type extends MessageType<QueryParam> {
    constructor() {
        super("enrollment.initialization_bff.api.v1.QueryParam", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<QueryParam>): QueryParam {
        const message = { key: "", value: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QueryParam>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryParam): QueryParam {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string key */ 1:
                    message.key = reader.string();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryParam, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string key = 1; */
        if (message.key !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.key);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.initialization_bff.api.v1.QueryParam
 */
export const QueryParam = new QueryParam$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitializeParticipantInfoRequest$Type extends MessageType<InitializeParticipantInfoRequest> {
    constructor() {
        super("enrollment.initialization_bff.api.v1.InitializeParticipantInfoRequest", [
            { no: 4, name: "plan_definition_id", kind: "scalar", oneof: "associatedResource", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "utm_params", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => QueryParam },
            { no: 3, name: "google_analytics_client_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "language", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<InitializeParticipantInfoRequest>): InitializeParticipantInfoRequest {
        const message = { associatedResource: { oneofKind: undefined }, utmParams: [], googleAnalyticsClientId: "", language: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InitializeParticipantInfoRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitializeParticipantInfoRequest): InitializeParticipantInfoRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string plan_definition_id */ 4:
                    message.associatedResource = {
                        oneofKind: "planDefinitionId",
                        planDefinitionId: reader.string()
                    };
                    break;
                case /* repeated enrollment.initialization_bff.api.v1.QueryParam utm_params */ 2:
                    message.utmParams.push(QueryParam.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string google_analytics_client_id */ 3:
                    message.googleAnalyticsClientId = reader.string();
                    break;
                case /* string language */ 5:
                    message.language = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitializeParticipantInfoRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string plan_definition_id = 4; */
        if (message.associatedResource.oneofKind === "planDefinitionId")
            writer.tag(4, WireType.LengthDelimited).string(message.associatedResource.planDefinitionId);
        /* repeated enrollment.initialization_bff.api.v1.QueryParam utm_params = 2; */
        for (let i = 0; i < message.utmParams.length; i++)
            QueryParam.internalBinaryWrite(message.utmParams[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string google_analytics_client_id = 3; */
        if (message.googleAnalyticsClientId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.googleAnalyticsClientId);
        /* string language = 5; */
        if (message.language !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.language);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.initialization_bff.api.v1.InitializeParticipantInfoRequest
 */
export const InitializeParticipantInfoRequest = new InitializeParticipantInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitializeParticipantInfoResponse$Type extends MessageType<InitializeParticipantInfoResponse> {
    constructor() {
        super("enrollment.initialization_bff.api.v1.InitializeParticipantInfoResponse", [
            { no: 1, name: "redirect_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<InitializeParticipantInfoResponse>): InitializeParticipantInfoResponse {
        const message = { redirectUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InitializeParticipantInfoResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitializeParticipantInfoResponse): InitializeParticipantInfoResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string redirect_url */ 1:
                    message.redirectUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitializeParticipantInfoResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string redirect_url = 1; */
        if (message.redirectUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.redirectUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.initialization_bff.api.v1.InitializeParticipantInfoResponse
 */
export const InitializeParticipantInfoResponse = new InitializeParticipantInfoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateMockCiamInfoRequest$Type extends MessageType<UpdateMockCiamInfoRequest> {
    constructor() {
        super("enrollment.initialization_bff.api.v1.UpdateMockCiamInfoRequest", [
            { no: 1, name: "person_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateMockCiamInfoRequest>): UpdateMockCiamInfoRequest {
        const message = { personId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateMockCiamInfoRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateMockCiamInfoRequest): UpdateMockCiamInfoRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string person_id */ 1:
                    message.personId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateMockCiamInfoRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string person_id = 1; */
        if (message.personId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.personId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.initialization_bff.api.v1.UpdateMockCiamInfoRequest
 */
export const UpdateMockCiamInfoRequest = new UpdateMockCiamInfoRequest$Type();
/**
 * @generated ServiceType for protobuf service enrollment.initialization_bff.api.v1.InitializationBffService
 */
export const InitializationBffService = new ServiceType("enrollment.initialization_bff.api.v1.InitializationBffService", [
    { name: "InitializeParticipantInfo", options: { "ciam.annotations.permissions": { allowUnauthenticated: true } }, I: InitializeParticipantInfoRequest, O: InitializeParticipantInfoResponse },
    { name: "UpdateMockCiamInfo", options: { "ciam.annotations.permissions": { allowUnauthenticated: true, ciam: ["CIAM_TEST_ALLOWED"] } }, I: UpdateMockCiamInfoRequest, O: Empty }
]);
