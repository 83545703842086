import {Stack, Typography} from '@mui/material';
import {Button} from '@verily-src/react-design-system';
import ErrorImage from '../../assets/illy_error.png';

const ErrorPage = () => {
  return (
    <Stack height={'100%'} justifyContent="center" alignItems={'center'}>
      <img data-testid="error-image" src={ErrorImage} alt="Error Image" />
      <Typography
        variant="h4"
        component="h2"
        sx={{mt: 2, mb: 2}}
        textAlign="center"
      >
        Loading Error
      </Typography>
      <Typography
        variant="body1"
        sx={{mb: 4, ml: 1, mr: 1, maxWidth: '360px'}}
        textAlign="center"
      >
        There was an error while loading your experience. Please try again in a
        couple of minutes.
      </Typography>
      <Button variant="filled" onClick={() => window.location.reload()}>
        Retry
      </Button>
    </Stack>
  );
};

export default ErrorPage;
