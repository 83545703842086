import {CircularProgress, Grid} from '@mui/material';

const Recruitment = () => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{minHeight: '100vh'}}
      data-testid="loading"
    >
      <Grid item xs="auto">
        <CircularProgress />
      </Grid>
    </Grid>
  );
};
export default Recruitment;
