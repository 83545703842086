import {CssBaseline, ThemeProvider} from '@mui/material';
import {ConsumerLightTheme} from '@verily-src/react-design-system';
import {InitializeParticipantInfoResponse} from '@verily-src/verily1-protos/enrollment/initialization-bff/api/v1/initialization_service';
import {useEffect, useState} from 'react';
import {navigateToUrl} from 'single-spa';
import {initializeParticipant} from '../../api/initializeParticipant';
import ErrorPage from '../../pages/Recruitment/Error';
import RecruitmentPage from '../../pages/Recruitment/Recruitment';
import {getInternalUrl} from '../../utils/getInternalUrl';

type RootProps = {
  name: string;
};

async function initializeParticipantAndRedirect(
  setHasError: React.Dispatch<React.SetStateAction<boolean>>
) {
  initializeParticipant()
    .then((response: InitializeParticipantInfoResponse) => {
      if (response.redirectUrl == '') {
        throw new Error('Redirect URL not found in response');
      }
      // If internal URL, use single-SPA internal routing to avoid reloading the whole app + latency
      const internalUrl = getInternalUrl(response.redirectUrl);
      if (internalUrl) {
        navigateToUrl(internalUrl);
      } else {
        window.location.replace(response.redirectUrl);
      }
    })
    .catch((error: Error) => {
      console.error('Error with InitializeParticipantInfo: ', error);
      setHasError(true);
    });
}

function prefetchModules() {
  // Prefetch some MFEs for faster navigation
  System.import('@verily-src/me-web-home');
  System.import('@verily-src/survey-mfe');
}

export default function Root(props: RootProps) {
  const [hasError, setHasError] = useState<boolean>(false);
  useEffect(() => {
    prefetchModules();
    initializeParticipantAndRedirect(setHasError);
  }, []);

  return (
    <ThemeProvider theme={ConsumerLightTheme}>
      <CssBaseline />
      {hasError ? <ErrorPage /> : <RecruitmentPage />}
    </ThemeProvider>
  );
}
