// Need to hoist public path setting to top so ES6 imports do not come before it.
import './publicPath';

import {Auth} from '@verily-src/auth';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import Root from './components/Root/Root';
import reportWebVitals from './reportWebVitals';
// This is used to expose PHAF global declarations.
import '@verily-src/phaf-runtime-helpers';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';

// set up Auth client for standalone mode
if (window.location.host.includes('localhost')) {
  const domain = config.getString('AUTH0_DOMAIN');
  const clientId = config.getString('AUTH0_CLIENT_ID');
  const audience = config.getString('AUTH0_AUDIENCE');
  Auth.setUpClient(domain, clientId, window.location.origin, audience);
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary() {
    // Customize the root error boundary for your microfrontend here.
    return <div>Oops! Something went wrong.</div>;
  },
});

export const {bootstrap, mount, unmount} = lifecycles;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
