import {
  InitializeParticipantInfoRequest,
  InitializeParticipantInfoResponse,
} from '@verily-src/verily1-protos/enrollment/initialization-bff/api/v1/initialization_service';
import i18n from '../i18n';
import createBFFClient from './createBFFClient';
import getRpcOptions from './getRpcOptions';

// get_ga_clientid parses out google analytics client id from wherever it can
export function getGaClientid(searchParams: URLSearchParams) {
  // TODO: change to use Google Analytics tag: https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id#getClientId
  // when analytics.js exists
  // try grabbing the client ID from the URL Search Params; it'll be the _ga
  // param for cross-domain tracking, and will only have been set on the
  // originating side if GA's been loaded, which only happens if the user has
  // consented in OneTrust.
  // The url param for cross-domain tracking looks like:
  //   _ga=2.19257979.255258106.1717519953-2083363348.1717519682
  // and the clientId in this case is 2083363348.1717519682 -> we split on '-'
  // and take the last part.
  for (const [key, value] of searchParams) {
    if (key === '_ga') {
      var splitValue = value.split('-');
      return splitValue[splitValue.length - 1];
    }
  }

  // if we don't have a _ga URL param, we might have a _gl param instead; that
  // one has the client ID sort-of base64-encoded and embedded in it, near the
  // string '_ga', with the '*' character as a delimiter and '.' instead of '='
  // as padding because '=' breaks URL parsing, i.e.
  //   _gl=<stuff>*_ga*OTUyNTA0NTE3LjE3MDkxNDAzNDk.*<stuff>
  // in this case, the client ID is b64-decode('OTUyNTA0NTE3LjE3MDkxNDAzNDk').
  // Note: you're allowed to drop the padding, so we do
  for (const [key, value] of searchParams) {
    if (key === '_gl') {
      var gaRegex = /_ga\*([^*]+)\*?/;
      var gaArr = value.match(gaRegex);
      if (gaArr) {
        return atob(gaArr[1].replaceAll('.', ''));
      }
    }
  }

  // If we don't have a _ga URL param, maybe we just happen to have loaded GA at
  // some point and set the cookie. Let's check that.
  var cookie: any = {};
  document.cookie.split(';').forEach(function (el) {
    var splitCookie = el.split('=');
    var key = splitCookie[0].trim();
    var value = splitCookie[1];
    cookie[key] = value;
  });
  // eg cookie format: _ga=1.4-2.XXXXXXXX.YYYYYYY
  return cookie['_ga'] ? cookie['_ga'].substring(6) : '';
}

// get_utm_params parses out utm params from url
export function getUtmParams(searchParams: URLSearchParams) {
  const utmParamMap = new Map();
  for (const [key, val] of searchParams) {
    // check for prefix with utm
    if (key.startsWith('utm_')) {
      utmParamMap.set(key, val);
    }
  }

  return Array.from(utmParamMap, ([key, value]) => ({key, value}));
}

export const initializeParticipant =
  async (): Promise<InitializeParticipantInfoResponse> => {
    const client = createBFFClient();
    let searchParams = new URL(window.location.href).searchParams;
    const clientID = getGaClientid(searchParams);

    const options = await getRpcOptions();
    const request: InitializeParticipantInfoRequest = {
      googleAnalyticsClientId: clientID || '',
      utmParams: getUtmParams(searchParams),
      associatedResource: {
        oneofKind: 'planDefinitionId',
        planDefinitionId: searchParams.get('plan_definition') || '',
      },
      language: i18n.language,
    };
    return await client.initializeParticipantInfo(request, options).response;
  };
